import Vue from 'vue'
import Router from 'vue-router'
import IndexView from '@/views/Index'
import DetailView from '@/views/Detail'
import ListView from '@/views/List'
import FormView from '@/views/Form'
import AdView from '@/views/Advertising'
import BoardView from '@/views/Board';
// import db from '@/utils/localstorage'
// import request from '@/utils/request'

// 全局Router异常处理
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  try { return originalPush.call(this, location) } catch (err) { if (typeof err !== 'undefined')console.log(err) }
}
Vue.use(Router)

let constRouter = [
  {
    path: '/',
    name: '首页',
    component: IndexView
  },
  {
    path: '/list',
    name: '列表页',
    component: ListView
  },
  {
    path: '/detail',
    name: '详情页',
    component: DetailView
  },
  {
    path: '/form',
    name: '提交信息',
    component: FormView
  },
  {
    path: '/advertising',
    name: '广告页',
    component: AdView
  },
  {
    path: '/zsgy',
    name: '叫号大屏',
    component: BoardView
  }
]

let router = new Router({
  mode: 'history',
  routes: constRouter
})

// const whiteList = ['/index']

// let asyncRouter

// 导航守卫，渲染动态路由
// router.beforeEach((to, from, next) => {
//   if (whiteList.indexOf(to.path) !== -1) {
//     next()
//   }
//   let token = db.get('USER_TOKEN')
//   let user = db.get('USER')
//   let userRouter = get('USER_ROUTER')
//   if (token.length && user) {
//     if (!asyncRouter) {
//       if (!userRouter) {
//         request.get(`menu/${user.username}`).then((res) => {
//           asyncRouter = res.data
//           save('USER_ROUTER', asyncRouter)
//           go(to, next)
//         }).catch(err => { console.error(err) })
//       } else {
//         asyncRouter = userRouter
//         go(to, next)
//       }
//     } else {
//       next()
//     }
//   } else {
//     next('/login')
//   }
// })

// function go (to, next) {
//   asyncRouter = filterAsyncRouter(asyncRouter)
//   router.addRoutes(asyncRouter)
//   next({...to, replace: true})
// }
//
// function save (name, data) {
//   localStorage.setItem(name, JSON.stringify(data))
// }
//
// function get (name) {
//   return JSON.parse(localStorage.getItem(name))
// }

// function filterAsyncRouter (routes) {
//   return routes.filter((route) => {
//     let component = route.component
//     if (component) {
//       switch (route.component) {
//         default:
//           route.component = view(component)
//       }
//       if (route.children && route.children.length) {
//         route.children = filterAsyncRouter(route.children)
//       }
//       return true
//     }
//   })
// }
//
// function view (path) {
//   return function (resolve) {
//     import(`@/views/${path}.vue`).then(mod => {
//       resolve(mod)
//     })
//   }
// }

export default router
