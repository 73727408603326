<template>
    <div class="call-board-box" ref="board">
        <header class="head">
            <h1>中山公园自驾船数字化运营系统</h1>
<!--            <button @click="exitFullScreen" class="full-screen" v-if="isFull"><van-icon name="shrink" :style="{fontSize: '32px'}" /></button>-->
            <button @click="fullScreen" class="full-screen" v-if="!isFull"><van-icon name="expand-o" :style="{fontSize: '32px'}" /></button>
        </header>
        <div class="mainbox">
            <ul class="clearfix">
                <li style="height: 958px;">
                    <div class="boxall info" style="height: calc(63% - 34px);">
                        <div class="title">
                            <img :src="require('@/assets/images/call_ship_free.png')" /><span>游船空闲实况</span>
                        </div>
                        <ul class="digit flex-box digit-top">
                            <li class="label"></li>
                            <li class="name">豪华大天鹅</li>
                            <li class="name">豪华小天鹅</li>
                        </ul>
                        <ul class="digit flex-box digit-center">
                            <li class="label">乘坐人数</li>
                            <li class="name">{{callArr[0]&&callArr[0].limit_number}}人</li>
                            <li class="name">{{callArr[1]&&callArr[1].limit_number}}人</li>
                        </ul>
                        <ul class="border">
                            <li class="short"><img src="../assets/images/call_border_short.png" /></li>
                            <li><img src="../assets/images/call_border.png" /></li>
                        </ul>
                        <ul class="digit flex-box digit-center">
                            <li class="label">上线数量</li>
                            <li class="name">{{callArr[0]&&callArr[0].online_num}}</li>
                            <li class="name">{{callArr[1]&&callArr[1].online_num}}</li>
                        </ul>
                        <ul class="border">
                            <li class="short"><img src="../assets/images/call_border_short.png" /></li>
                            <li><img src="../assets/images/call_border.png" /></li>
                        </ul>
                        <ul class="digit flex-box digit-center">
                            <li class="label">空闲数量</li>
                            <li class="name">{{callArr[0]&&callArr[0].free_num}}</li>
                            <li class="name">{{callArr[1]&&callArr[1].free_num}}</li>
                        </ul>
                        <ul class="border">
                            <li class="short"><img src="../assets/images/call_border_short.png" /></li>
                            <li><img src="../assets/images/call_border.png" /></li>
                        </ul>
                        <ul class="digit flex-box digit-bottom">
                            <li class="label">当前叫号</li>
                            <li class="name call-num">{{callArr[0]&&(callArr[0].call_num ? callArr[0].call_num : '无')}}</li>
                            <li class="name call-num">{{callArr[1]&&(callArr[1].call_num ? callArr[1].call_num : '无')}}</li>
                        </ul>
                    </div>
                    <div class="boxall process" style="height: 37%;">
                        <div class="title">
                            <img :src="require('@/assets/images/call_ship_scan.png')" /><span>微信扫码叫号</span>
                        </div>
                        <ul class="pic-txt flex-box">
                            <li class="qrcode-box" ref="qrcode"></li>
                            <li class="process-box">
                                <ul>
                                    <li class="hint">游船租赁流程</li>
                                    <li class="process-item" name="1">扫码排号</li>
                                    <li class="process-item" name="2">扫码租船</li>
                                    <li class="process-item" name="3">站点还船</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="map-box" style="height: 914px;">
                    <div class="title">
                        <img :src="require('@/assets/images/call_ship_pos.png')" /><span>游船实时位置</span>
                        <span id="showTime">{{showTime}}</span>
                    </div>
                    <div class="notice" v-show="notice&&notice.title">{{notice.title}}</div>
                    <div class="map">
                        <baidu-map id="map" :center="center" :scroll-wheel-zoom="true" :zoom="zoom" :max-zoom="zoom" @ready="handler" ak="Bg33H82g4nnLwmEGYdMUxV4lGfinNXwX"></baidu-map>
                    </div>
                </li>
            </ul>
        </div>
        <div class="back"></div>
    </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
// import {Toast} from 'vant'
import { RES_NORMAL_SUCCESS_CODE } from '@/utils/constants'
import QR from '@/utils/qrcode'
export default {
    name: 'Board',
    components: { BaiduMap },
    data () {
        return {
            showTime: '',
            isFull: false,
            callArr: [],
            picUrl: '',
            center: {lat: 0, lng: 0},
            zoom: 21,
            notice: {},
            posArr: []
        }
    },
    created () {
        this.fetch()
        this.timer = setInterval(() => {
            this.getShowTime()
        }, 1000)
        this.callTimer = setInterval(() => {
            this.getCallInfo()
        }, 5000)
        this.ntTimer = setInterval(() => {
            this.getNotice()
        }, 600000)
        this.posTimer = setInterval(() => {
            this.getDevicePos()
        }, 60000)
    },
    mounted() {
        this.getQrcode()
    },
    beforeDestroy () {
        clearInterval(this.timer)
        clearInterval(this.callTimer)
        clearInterval(this.ntTimer)
        clearInterval(this.posTimer)
    },
    methods: {
        fetch () {
            this.getShowTime()
            this.getCallInfo()
            this.getNotice()
        },
        getCallInfo () {
            this.$get('/open/call/zsgy_callInfo').then(res => {
                if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
                    if (res.data.data && Array.isArray(res.data.data)) {
                        this.callArr = res.data.data
                        this.callArr.sort((a, b) => { return b.limit_number - a.limit_number })
                    } else {
                        // Toast.fail({ message: '无叫号信息', forbidClick: true });
                        console.log(res.data)
                    }
                } else {
                    // Toast.fail({ message: '获取叫号信息失败', forbidClick: true });
                    console.log(res.data)
                }
            }).catch(err => {
                // Toast.fail({ message: '获取叫号信息失败', forbidClick: true });
                console.error(err)
            })
        },
        getQrcode () {
            this.$get('/open/call/zsgy_callQRCode').then(res => {
                if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
                    if (res.data.data && res.data.data.includes && (res.data.data.includes('evhack'))) {
                        this.url = res.data.data
                        this.qrcode = new QR(this.$refs.qrcode, {
                            text: this.url,
                            width: 214,
                            height: 214,
                            colorDark : '#000000',
                            colorLight : '#ffffff',
                            correctLevel : QR.CorrectLevel.H
                        })
                        this.qrcode.makeCode(this.url)
                    } else {
                        // Toast.fail({ message: '链接地址无效', forbidClick: true });
                        console.log(res.data)
                    }
                } else {
                    // Toast.fail({ message: '获取二维码链接失败', forbidClick: true });
                    console.log(res.data)
                }
            }).catch(err => {
                // Toast.fail({ message: '获取二维码链接失败', forbidClick: true });
                console.error(err)
            })
        },
        getNotice () {
            this.$get('/open/call/zsgy_notice').then(res => {
                if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
                    if (res.data.data && res.data.data[0]) {
                        this.notice = res.data.data[0]
                    }
                } else {
                    // Toast.fail({ message: '获取通知失败', forbidClick: true });
                    console.log(res.data)
                }
            }).catch(err => {
                // Toast.fail({ message: '获取通知失败', forbidClick: true });
                console.error(err)
            })
        },
        getDevicePos () {
            this.$get('/open/call/zsgy_location').then(res => {
                if (res.data.code === RES_NORMAL_SUCCESS_CODE) {
                    if (res.data.data && Array.isArray(res.data.data)) {
                        this.map && this.map.clearOverlays()
                        this.posArr = res.data.data
                        this.posArr.forEach(pos => {
                            const point = new this.BMap.Point(pos.lng, pos.lat)
                            const myIcon = new this.BMap.Icon(require('@/assets/images/marker.png'), new this.BMap.Size(28, 28), {
                                anchor: new this.BMap.Size(14, 14), imageOffset: new this.BMap.Size(0, 0)
                            })
                            const marker = new this.BMap.Marker(point, {icon: myIcon})
                            this.map.addOverlay(marker)
                        })
                    } else {
                        // Toast.fail({ message: '无定位信息', forbidClick: true });
                        console.log(res.data)
                    }
                } else {
                    // Toast.fail({ message: '获取设备定位失败', forbidClick: true });
                    console.log(res.data)
                }
            }).catch(err => {
                // Toast.fail({ message: '获取设备定位失败', forbidClick: true });
                console.error(err)
            })
        },
        handler ({BMap, map}) {
            this.BMap = BMap
            this.map = map
            this.center.lng = 114.278325
            this.center.lat = 30.589009
            this.zoom = 21
            this.getDevicePos()
        },
        getShowTime () {
            const date = new Date()
            let str = ''
            const year = date.getFullYear()
            let month = date.getMonth() + 1
            let day = date.getDate()
            let hour = date.getHours()
            let minute = date.getMinutes()
            let second = date.getSeconds()
            month = month > 9 ? month : ('0' + month)
            day = day > 9 ? day : ('0' + day)
            hour = hour > 9 ? hour : ('0' + hour)
            minute = minute > 9 ? minute : ('0' + minute)
            second = second > 9 ? second : ('0' + second)
            str += year + '年' + month + '月' + day + '日' + ' ' + hour + ':' + minute + ':' + second
            this.showTime = str
        },
        fullScreen () {
            const docElm = this.$refs.board;
            this.isFull = true
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            }
        },
        exitFullScreen () {
            this.isFull = false
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .call-board-box {
        width: 100%;
        .head{height:126px;line-height:126px;text-align:center;
            background:url(../assets/images/call_top_bg.png) #000000 no-repeat center center;
            background-size:100% 100%;position:relative;z-index:10;}
        .head h1{ margin: 0;color:#FFFFFF; font-size: 48px;font-weight: bold;}
        #showTime{position: absolute; right: 30px; top: 0; z-index: 12; font-size: 32px; color:#FFFFFF;font-weight: normal;}
        .full-screen { position: absolute; top: 20px; right: 36px; line-height: 28px; border: none; background: transparent; color: #FFFFFF; }
        .mainbox { background-color: #000000; }
        ul.clearfix:before,.clearfix:after {display: table;content: " ";}
        ul.clearfix:after {clear: both;}
        ul.clearfix { list-style-type: none; padding: 0; margin: 0; }
        ul.clearfix > li { position: relative; box-sizing: border-box; width: 36%; padding: 22px 22px 22px 32px; float: left; }
        ul.clearfix > li:last-child { width: calc(64% - 42px); padding: 0; margin: 22px 32px 22px 10px; }
        ul.clearfix .info,ul.clearfix .process,ul.clearfix .map-box {
            box-sizing: border-box;width: 100%;height: auto;background-color: #042A53;position:relative;z-index:10;
            border: 2px solid #0096FF; ;border-radius: 16px;box-shadow: 2px 2px 12px 2px #59FFFD;}
        ul.clearfix .info:before, ul.clearfix .process:before,ul.clearfix .map-box:before {
            content:"";position:absolute;bottom:0;top:-2px;left:-2px;right:0;z-index:-9;border-radius: 16px;
            box-shadow: -2px -2px 12px 2px #59FFFD;}
        ul.clearfix .info { margin-bottom: 30px; }
        ul.clearfix .info .title,ul.clearfix .map-box .title,ul.clearfix .process .title {
            border-top-left-radius: 16px;border-top-right-radius: 16px;background-color: #003671;
            background-image: linear-gradient(90deg, #003671, #0D4891, #003671);}
        ul.clearfix .map-box .title { position: relative; }
        ul.clearfix .info .title,ul.clearfix .process .title,ul.clearfix .map-box .title {
            display: flex;justify-content: flex-start;align-items: center;height: 52px;line-height: 52px;color: #59FFFD;
            font-size: 32px;font-weight: bold;text-align: left;}
        ul.clearfix .info .title img,ul.clearfix .process .title img,ul.clearfix .map-box .title img {
            margin: 0 16px 0 28px;vertical-align: middle;}
        .flex-box {display: flex;justify-content: center;align-items: center;}
        .digit { color: #FFFFFF; }
        .digit .label { flex-basis: 140px; text-align: center; font-size: 24px; vertical-align: middle; line-height: 1; }
        .digit .name { flex: 1; font-size: 36px; font-weight: bold; }
        .digit .call-num { font-size: 72px; color: #FFD200; }
        .digit-top { margin-top: 40px; }
        .digit-center { margin-top: 24px; }
        .digit-bottom { margin-top: 18px; }
        .border { position: relative;}
        .border .short { position: absolute;bottom: 0;left: 20px; z-index: 10; }
        .pic-txt { margin: 18px 30px; }
        .pic-txt .qrcode-box { flex-basis: 248px;box-sizing: border-box;width: 248px; height: 244px; padding: 15px 17px;
            background:url(../assets/images/call_qrcode_border.png) no-repeat center center;background-size:100% 100%;}
        .qrcode-box img { width: 214px; height: 214px; }
        .pic-txt .process-box { flex: 1; padding: 0 40px 0 60px;color: #FFFFFF; }
        .pic-txt .process-box .hint { font-size: 36px; font-weight: bold; }
        .pic-txt .process-box .process-item {position: relative;box-sizing: border-box;height:46px;line-height:46px;font-size:26px;
            margin-top:15px;padding-left: 20px;border:2px solid #0B407C;border-radius:25px;}
        .pic-txt .process-box .process-item:before { position: absolute;top: -2px;left: -2px;z-index: 20;content: attr(name);
            width: 46px;height: 46px;line-height: 46px;font-size: 26px;border-radius: 50%;background-color: #0B407C;}
        .map-box .notice {position: absolute;top: 69px;left: 32px;z-index: 999;width: calc(100% - 64px);height: 92px;box-sizing: border-box;
            line-height: 92px;background-color: #001D7A;color: #FFD200;font-size: 36px;font-weight: bold;opacity: 0.6;
            border-radius: 16px;padding: 0 53px;text-align: left;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;}
        #map { height: 100%; }
        .map { height: calc(100% - 52px);border-bottom-left-radius: 16px; border-bottom-right-radius: 16px; overflow: hidden;}
    }
</style>